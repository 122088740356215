<template>
  <div class="page-content" v-if="itemDetail">
    <h2>Item Class Detail</h2>
    <section class="my-3">
      <b-card class="mt-1">
        <b-row>
          <b-col cols="12">
            <b-form-group label="Type" label-for="h-type">
              <b-form-select v-model="itemDetail.type" :options="types_options" />
            </b-form-group>
            <b-form-group
              v-if="itemLevel"
              label="Variant"
              label-for="h-variant"
            >
              <b-form-select v-model="itemDetail.variant" :options="itemVariant" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type != 'BOX'"
              :label="itemLevelLabel"
              label-for="h-size"
            >
              <b-form-select v-model="itemDetail.tier" :options="itemLevel" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type == 'SMOOTHY'"
              label="Duration (minutes)"
              label-for="h-duration"
            >
              <b-form-input v-model="itemDetail.effect.duration" type="number" />
            </b-form-group>
            <b-form-group
              v-if="itemDetail.type == 'EQUIPMENT'"
              label="Require level"
              label-for="h-require-level"
            >
              <b-form-input v-model="itemDetail.require_level" type="number" />
            </b-form-group>
            <b-form-group
              label="Random effect"
              label-for="h-randomized"
            >
              <b-form-checkbox v-model="itemDetail.effect.is_randomized" />
            </b-form-group>
            <b-form-group
              v-if="!itemDetail.effect.is_randomized"
              label="Effect value"
              label-for="h-value"
            >
              <b-form-input v-model="itemDetail.effect.value" type="number" />
            </b-form-group>
            <template v-else>
              <b-form-group
                label="Min effect value"
                label-for="h-min-value"
              >
                <b-form-input v-model="itemDetail.effect.min_value" type="number" />
              </b-form-group>
              <b-form-group
                label="Max effect value"
                label-for="h-max-value"
              >
                <b-form-input v-model="itemDetail.effect.max_value" type="number" />
              </b-form-group>
            </template>
          </b-col>
        </b-row>
      </b-card>
    </section>
    <action-buttons
      update-button
      back-button
      delete-button
      @update="update"
      :is-updating="isUpdating"
      @delete="deleteClass"
      :is-deleting="isDeleting"
    />
  </div>
</template>
<script>
import service from '../service';
import config from '../../items/config'
export default {
  data(){
    return{
      itemDetail: null,
      types_options:[
        {text: "Smoothies", value: "SMOOTHY"},
        {text: "Clothes", value: "EQUIPMENT"},
        // {text: "Box", value: "BOX"},
      ],
      category_options:[
        {text: "Shirt", value: "SHIRT"},
        {text: "Trousers", value: "TROUSERS"},
        {text: "Shoes", value: "SHOES"},
      ],
      item_level_options:[],
      isUpdating: false,
      isDeleting: false,
      itemLevelLabel: null
    }
  },
  computed: {
    itemId() {
      return this.$route.params.item_id;
    },
    breadcrumbItems() {
      let items = [
        {
          text: "Item List",
          to: { name: "item-list" },
        },
      ];
      items.push({ text: "Item Detail", active: true });
      return items;
    },
    itemVariant(){
      if (this.itemDetail.type == "SMOOTHY"){
        return config.SMOOTHY
      }
      if (this.itemDetail.type == "EQUIPMENT"){
        return config.EQUIPMENT
      }
      if (this.itemDetail.type == "BOX"){
        return null
      }
      return null
    },
    itemLevel(){
      if (this.itemVariant){
        let variant = this.itemVariant.find((x)=>{
        return x.value == this.itemDetail.variant
        })
        return variant ? variant.tier : null
      }
      return null
    }
  },
  watch:{
    "itemDetail.type":{
      deep: true,
      handler: function(newVal){
        if (newVal == "SMOOTHY"){
          this.itemLevelLabel = "Size"
        }
        if (newVal == "EQUIPMENT"){
          this.itemLevelLabel = "Tier"
        }
      }
    }
  },
  created(){
    this.getItem();
  },
  methods:{
    async getItem(){
      this.itemDetail = await service.get({id: this.itemId})
    },
    async update() {
      this.isUpdating = true;
      await service.update({
        data: JSON.stringify(this.itemDetail),
      });
      this.getItem();
      this.isUpdating = false;
    },
    async deleteClass() {
      await service.delete({ id: this.itemId });
      this.$router.push({ name: "items-class-list" });
    },
  }
}
</script>